import {
  Container,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  useColorModeValue,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn, MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./CustomResolver";
import { ContactForm } from "./types";

export default function Contact() {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {
    const requestOption = {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data,
      }),
    };

    const response = await fetch(`/api/v1`, requestOption);
    const status = await response.status;

    if (status === 200) {
      toast({
        title: "Thanks for your time.",
        description:
          "Message delivered successfully, I will responding you ASAP.",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      reset();
    }
  };

  return (
    <Container
      bg={useColorModeValue("gray.100", "gray.700")}
      maxW="full"
      minH="calc(100vh - 125px)"
      mt={0}
      centerContent
      overflow="hidden"
    >
      <Box
        bg="white"
        color="black"
        borderRadius="lg"
        m={{ sm: 4, md: 16, lg: 10 }}
        p={{ sm: 5, md: 5, lg: 16 }}
      >
        <Box p={4}>
          <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
            <WrapItem>
              <Box>
                <Heading size="lg">Get In Touch</Heading>
                <Text
                  fontSize="md"
                  mt={{ sm: 3, md: 3, lg: 5 }}
                  color="gray.600"
                >
                  Fill up the form below to contact
                </Text>
                <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                  <VStack pl={0} spacing={3} alignItems="flex-start">
                    <Button
                      size="md"
                      height="48px"
                      width="210px"
                      variant="ghost"
                      color="black"
                      _hover={{ border: "2px solid #1C6FEB" }}
                      leftIcon={<MdPhone color="#1970F1" size="20px" />}
                    >
                      +52-4426752014
                    </Button>
                    <Button
                      size="md"
                      height="48px"
                      width="325px"
                      variant="ghost"
                      color="#black"
                      _hover={{ border: "2px solid #1C6FEB" }}
                      leftIcon={<MdEmail color="#1970F1" size="20px" />}
                    >
                      mohammedkhalid11@gmail.com
                    </Button>
                    <Button
                      size="md"
                      height="48px"
                      width="190px"
                      variant="ghost"
                      color="black"
                      _hover={{ border: "2px solid #1C6FEB" }}
                      leftIcon={<MdLocationOn color="#1970F1" size="20px" />}
                    >
                      Chennai, India
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </WrapItem>
            <WrapItem>
              <Box bg="white" borderRadius="lg">
                <Box m={8} color="#0B0E3F">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={5}>
                      <FormControl
                        isInvalid={errors.name?.message ? true : false}
                      >
                        <FormLabel>Your Name</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsPerson color="gray.800" />}
                          />
                          <Input type="text" size="md" {...register("name")} />
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.name?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        id="email"
                        isInvalid={errors.email?.message ? true : false}
                      >
                        <FormLabel>Mail</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdOutlineEmail color="gray.800" />}
                          />
                          <Input type="text" size="md" {...register("email")} />
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.email?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        id="comment"
                        isInvalid={errors.comment?.message ? true : false}
                      >
                        <FormLabel>Message</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: "gray.300",
                            }}
                            w="xs"
                            placeholder="message"
                            {...register("comment")}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {errors.comment?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl float="right">
                        <Button
                          type="submit"
                          variant="solid"
                          bg="#E2E8F0"
                          color="black"
                        >
                          Send Message
                        </Button>
                      </FormControl>
                    </VStack>
                  </form>
                </Box>
              </Box>
            </WrapItem>
          </Wrap>
        </Box>
      </Box>
    </Container>
  );
}
