import { ReactElement } from "react";
import { Rating } from 'react-simple-star-rating'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useColorModeValue,
  Box,
  Stack,
  Flex,
  Heading,
  Icon,
} from "@chakra-ui/react";
import {
  isTechnologyModalOpen,
  setTechnologyModal,
} from "../features/config/configSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { ExperiencedTechnology } from "../constants/Technology";


interface CardProps {
  heading: string
  level: number
  icon: ReactElement
}

const Card = ({ heading, level, icon }: CardProps) => {
  return (
    <Box
      maxW={{ base: 'full', md: '300px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}>
      <Stack align={'center'} spacing={2}>
        <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}>
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
        </Box>
        <Box
          alignItems="start"
          textAlign="initial"
          maxW="full"
        >
          <Rating initialValue={level} SVGstyle={{display: 'inline-block'}} size={25} iconsCount={10} allowFraction readonly/>
        </Box>
      </Stack>
    </Box>
  )
}

export default function TechnologyModal() {
  const isOpen = useAppSelector(isTechnologyModalOpen);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setTechnologyModal(false));
  };

  return (
    <>
      <Modal isOpen={isOpen} size="5xl" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={'center'}>Technical Experience</ModalHeader>
          <ModalCloseButton />
          <ModalBody display={"flex"} flexDirection={"column"} gap={"10px"} pb={6}>
      
        <Flex flexWrap="wrap" gridGap={6}>
        {ExperiencedTechnology.map((technology) => 
          <Card
            heading={technology.tech}
            icon={<Icon as={technology.icon} w={10} h={10} color={'blue.900'} />}
            level={technology.level}
          />
        )}
        </Flex>
            {/* {experiencedTechnology.map((technology) => (
              <Stack direction={["column", "row"]}>
                <Box w="30%">
                  <Text color={"gray.600"} fontSize="xl" fontWeight={"bold"} fontFamily={"monospace"}>
                    {technology.tech}
                  </Text>
                </Box>
                <Box
                  as={Container}
                  alignItems="start"
                  textAlign="initial"
                  maxW="full"
                  m="auto"
                >
                  <Progress size="xs" colorScheme="teal" max={10} hasStripe value={technology.level} />
                </Box>
              </Stack>
            ))} */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
