const Projects = [
  {
    title: "Convera - Payment Dashboard",
    summary: `It is a global payments product for businesses and streamline cross-border payments securing more
value in every transaction. I have worked with the dashboard module where it shows all the transaction events,
quick quotes, recent orders, frequent payees and payment outcome.`,
    technology:
      "ReactJs, Lerna, Jest, Less, D3, JavaScript(es6), Typescript, Redux.",
    responsibility: "",
  },
  {
    title: "Risk Detect Lending",
    summary: `This project automates compliance testing processes by extracting required fields from document
scans and comparing that data to what is stored in source system and being reported to customers or regulators.
I have worked on fixing bugs.`,
    technology:
      "ReactJs, Remix, HTML, CSS, JavaScript(es6), TypeScript, Redux.",
    responsibility: "",
  },
  {
    title: "SpaceWarp",
    summary: `This is the warehousing product, Where all kind of supermarket can manage and store their all kind of
product efficiently, I have worked on the dashboard part to see all the products are aligned in racks and to
retrieve the required products which the client or employee needed.`,
    technology:
      "ReactJs, HTML, CSS, JavaScript(es6), TypeScript, Redux, ThreeJs.",
    responsibility: "",
  },
  {
    title: "Imageware - LE (Law Enforcement)",
    summary: ``,
    technology: "",
    responsibility: "",
  },
  {
    title: "Data Sync Admin UI (Autozone)",
    summary: `This is the internal project for autozone to maintain the stores available in United States and Mexico.
This Project contain to add and update the Store details, Event details, Destination details. I’m will be working on
my sprint task and also helping team to finish there task on time.`,
    technology: "ReactJs, HTML, CSS, JavaScript(es6), TypeScript, Redux.",
    responsibility: "",
  },
  {
    title: "Myca (https://www.myca.ai/)",
    summary: ``,
    technology: "",
    responsibility: "",
  },
  {
    title: "Dollar Shave Club (https://www.dollarshaveclub.com/)",
    summary: `It is E-commerce project, that delivers razors and other personal grooming products for men. I have
build the Account Details page , Product Box (Now Box and Next Box) page, Dashboard page after login,
ProductDetails page.`,
    technology:
      "ReactJs, HTML, CSS, JavaScript(es6), Typescript, Redux, Router.",
    responsibility: "",
  },
  {
    title: "NBA",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Retail Management System (T-Mobile)",
    summary: `This is the migration project from desktop application to Mobile friendly UI application. The
requirement to build the functionality in 6 flows at initial launch. Login view, Account Search, Change Plan, Bill
Pay, Upgrade and Accessories Sale.`,
    technology:
      "Angular 8, HTML, CSS, JavaScript(es6), ExpressJs, Node, Docker",
    responsibility: "",
  },
  {
    title: "Ticketmaster",
    summary:
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam.",
    technology: "",
    responsibility: "",
  },
  {
    title: "Apigee Training App",
    summary: `This is the internal project; purpose of the tool is for cost cutting. Prokarma is a partner with Apigee,
many developers are taking the Apigee certification, and it cost around 300 dollars. The agenda is before
appearing to the real taste, they can take the test in this app. So that it can reduce the failure possibility in
Apigeecertification.`,
    technology: "ReactJs, Redux, JavaScript(es6), ExpressJs, CSS, Node.",
  },
  {
    title: "Safetix Config Tool",
    summary: `The purpose of the tool is to search and display the events based on variety of filtering like event
name, event id, venue name, venue id. This tool also updates the segmentation (available ticket percentage). In
this tool implemented the OKTA identification and segmentation monitoring (Notification in slack).`,
    technology: "ReactJs, Redux, JavaScript(es6), ExpressJs, CSS, Node.",
  },
  {
    title: "AML- Customer Due Diligence (CDD)",
    summary: `The purpose of the project is to overcome the money laundering; we serve for military members and
their families. Agent will create the bank account for the customer with his details, we will be asking all required
questions from the customers. In this project I developed customer creation screens.`,
    technology: "ReactJs, Redux, JavaScript(es6), CSS, Node.",
  },
  {
    title: "Marketplace",
    summary: `It is a site which provides all Travel agency software, acts as an store for Travel account. Here I
worked in visualizing all the registered Travel company software’s and registration screen and as well search
screen. Provided different level of screen visualization based on their designation.`,
    technology: "ReactJs, Redux, JavaScript (es6), HTML, CSS, Node.",
  },
  {
    title: "Travelport Rail Service",
    summary: `It is a Travel Commerce Platform, focused on providing distribution, technology, payment and other
solution. I was dedicated working in Rail segment for option to search for rail availability using different city code
or train station code, add passenger, select different routes and fairs, book ticket, modify booking, issue ticket,
cancel booking.`,
    technology: "ReactJs, Redux, JavaScript (es6), HTML, CSS, Node.",
  },
  {
    title: "KingsTrack (https://mvt.thegoldenelement.com/)",
    summary: `It is a GPS tracking product used in vehicles (bike and car), personal tracking, School tracking.
There are so many features in application like live Tracking data, Track history, Alerts (Fuel danger, seat belt, AC On/
Off), Reports, Geo zone violating alert, Dashboard show the status of the vehicle. In this project I got good
knowledge in Google map API,`,
    technology: "Angular, jQuery, Bootstrap, HTML, CSS, JQWidgets",
  },
  {
    title:
      "ArmorOn (https://play.google.com/store/apps/details?id=com.armoron10.apmkingstrack)",
    summary: `It is a Tracking product specifically for bike and auto (three-wheeler) in android application. Some
features like anti-Theft alarm, Live Track, 2 days Track history, change Number settings screen. In this project I
got knowledge in Ionic and basic of Cordova.`,
    technology: "Ionic 1",
  },
];

export default Projects;
