import { FaHtml5, FaCss3Alt, FaReact, FaBootstrap, FaNodeJs } from "react-icons/fa";
import { TbBrandJavascript, TbBrandTypescript } from "react-icons/tb";
import { SiRedux, SiMui, SiExpress } from "react-icons/si";

export const ExperiencedTechnology = [
    {
      tech: "HTML",
      icon: FaHtml5,
      level: 8,
    },
    {
      tech: "CSS",
      icon: FaCss3Alt,
      level: 8,
    },
    {
      tech: "JavaScript",
      icon: TbBrandJavascript,
      level: 7,
    },
    {
      tech: "ReactJs",
      icon: FaReact,
      level: 8,
    },
    {
      tech: "Redux",
      icon: SiRedux,
      level: 8,
    },
    {
      tech: "Typescript",
      icon: TbBrandTypescript,
      level: 7,
    },
    {
      tech: "Bootstrap",
      icon: FaBootstrap,
      level: 8,
    },
    {
      tech: "Mui react",
      icon: SiMui,
      level: 8,
    },
    {
      tech: "ExpressJs",
      icon: SiExpress,
      level: 6,
    },
    {
      tech: "NodeJS",
      icon: FaNodeJs,
      level: 6,
    },
  ];